import { Controller } from "stimulus";
import I18n from 'i18n-js';

export default class extends Controller {
  static values = { optionsCount: Number }
  static targets = ['addOption', 'template', 'option']

  addAssociation(event){
    event.preventDefault();
    this.optionsCountValue += 1;
    let content = this.templateTarget.innerHTML.replace(/TEMPLATE_RECORD/g, parseInt(Date.now()));
    content = content.replace(I18n.t('views.surveys.placeholder_option_number', { number: 3 }), I18n.t('views.surveys.placeholder_option_number', { number: this.optionsCountValue }) );
    this.addOptionTarget.insertAdjacentHTML('beforeBegin', content);
  }

  removeAssociation(event){
    event.preventDefault();
    let item = event.target.closest('.nested-fields');
    item.querySelector("input[name*=_destroy").value = 1;
    item.querySelector("input[name*=value").eliminated = 1;
    item.style.display = 'none';
    this.optionsCountValue -= 1;
    let changed = false;
    let counter = 1;
    this.optionTargets.forEach((target) => {
      if (target == item){
        changed = true;
      }
      if (target.style.display != 'none') {
        if (changed){
          target.querySelector("input[name*='value'").placeholder = I18n.t('views.surveys.placeholder_option_number', { number: counter });
        }
        counter += 1;
      }
    });
  }
}
